import React from "react"
import styled from "styled-components"
// import { media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"
import { useStaticQuery, graphql } from "gatsby"

export default ({ dimmed, className }) => {
  const { wordpressAcfOptions } = useStaticQuery(graphql`
    query SocialMediaQuery {
      wordpressAcfOptions {
        options {
          site_socials {
            urls {
              title
              url
            }
          }
        }
      }
    }
  `)

  const items = wordpressAcfOptions.options.site_socials.urls

  return (
    <List className={(className || "") + (dimmed ? " -dimmed" : "")}>
      {items.map(({ title, url }, index) => (
        <Item key={index}>
          <ItemLink
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            aria-label={title}
          >
            <StyledIcon name={title} />
          </ItemLink>
        </Item>
      ))}
    </List>
  )
}

const List = styled.ul`
  display: flex;
  margin-left: -8px;
`

const Item = styled.li``

const ItemLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  color: #000;
  transition: 0.1s;

  &:hover {
    color: ${p => p.theme.color.grey};
  }

  ${List}.-dimmed & {
    opacity: 0.2;

    &:hover {
      opacity: 1;
      color: #000;
    }
  }
`

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`
